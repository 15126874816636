<template>
  <div class="filmdetails">
    <!-- 视频播放部分 -->
    <div class="px-88 py-50 film_top">
      <div style="height: 5.625rem"></div>
      <div class="flex">
        <!-- 视频播放器 -->
        <div v-if="info.video" class="videobox">
          <videoplay
            :videoData="videoData"
            :src="info.video"
            :poster="info.img"
            :autoplay="autoplay"
            :film_id="info.id"
          ></videoplay>
          <div class="videoplaybtn font-14 text-white">
            <div
              class="flex flex-column align-center justify-center mb-40 cursorp"
              @click="giveLike"
            >
              <div class="mb-15">
                <!-- <img
                  src="@/assets/img/selevideozan.png"
                  width="1.625rem"
                  height="1.4375rem"
                  style="width: 1.625rem; height: 1.4375rem"
                  alt=""
                  v-if="info.is_fabulous == 1"
                /> -->
                <el-image
                  style="width: 1.625rem; height: 1.4375rem"
                  :src="require('@/assets/img/selevideozan.png')"
                  fit="cover"
                  v-if="info.is_fabulous == 1"
                ></el-image>
                <el-image
                  style="width: 1.625rem; height: 1.4375rem"
                  :src="require('@/assets/img/videozan.png')"
                  fit="cover"
                  v-else
                ></el-image>
                <!-- <img
                  src="@/assets/img/videozan.png"
                  width="1.625rem"
                  height="1.4375rem"
                  alt=""
                  style="width: 1.625rem; height: 1.4375rem"
                  v-else
                /> -->
              </div>
              {{ info.fabulous_nums }}
            </div>
            <div
              class="flex flex-column align-center justify-center mb-40 cursorp"
              @click="commentreply(1, 'item')"
            >
              <div class="mb-15">
                <!-- <img
                  src="@/assets/img/pinglun.png"
                  width="1.625rem"
                  height="1.625rem"
                  alt=""
                  style="width: 1.625rem; height: 1.625rem"
                /> -->
                <el-image
                  style="width: 1.625rem; height: 1.625rem"
                  :src="require('@/assets/img/pinglun.png')"
                  fit="cover"
                ></el-image>
              </div>
              {{ info.comment_nums }}
            </div>
            <div
              class="flex flex-column align-center justify-center mb-40 cursorp"
              @click="givecollect"
            >
              <div class="mb-15">
                <!-- <img
                  src="@/assets/img/selevideoshoucang.png"
                  width="1.625rem"
                  height="1.5625rem"
                  alt=""
                  style="width: 1.625rem; height: 1.5625rem"
                  v-if="info.is_collection == 1"
                /> -->
                <el-image
                  style="width: 1.625rem; height: 1.5625rem"
                  :src="require('@/assets/img/selevideoshoucang.png')"
                  fit="cover"
                  v-if="info.is_collection == 1"
                ></el-image>
                <el-image
                  style="width: 1.625rem; height: 1.5625rem"
                  :src="require('@/assets/img/videoshoucang.png')"
                  fit="cover"
                  v-else
                ></el-image>
                <!-- <img
                  src="@/assets/img/videoshoucang.png"
                  width="1.625rem"
                  height="1.5625rem"
                  alt=""
                  style="width: 1.625rem; height: 1.5625rem"
                  v-else
                /> -->
              </div>
              {{ info.collection_nums }}
            </div>
            <div class="flex flex-column align-center justify-center mb-40 cursorp">
              <div class="mb-15">
                <!-- <img
                  src="@/assets/img/video.png"
                  width="1.25rem"
                  height="1.625rem"
                  style="width: 1.25rem; height: 1.625rem"
                  alt=""
                /> -->
                <el-image
                  style="width: 1.25rem; height: 1.625rem"
                  :src="require('@/assets/img/video.png')"
                  fit="cover"
                ></el-image>
              </div>
              {{ info.play_nums }}
            </div>
          </div>
        </div>
        <!-- 评论 -->
        <div class="comment">
          <div class="py-30 px-25 border-bottom border-262629">
            <!-- 头像/关注 -->
            <div class="mb-20 flex align-center justify-between text-white">
              <div
                class="font-20 flex align-center justify-center cursorp"
                @click="gouserInfo"
              >
                <!-- <img
                  :src="info.avatar"
                  width="3.25rem"
                  height="3.25rem"
                  class="circle"
                  style="width: 3.25rem; height: 3.25rem"
                  alt=""
                /> -->
                <el-image
                  class="circle"
                  style="width: 3.25rem; height: 3.25rem"
                  :src="info.avatar"
                  fit="cover"
                ></el-image>
                <div class="ml-10">
                  {{ info.username }}
                </div>
              </div>
              <div
                class="flex align-center justify-center coll attention cursorp"
                v-if="info.user_id != uid && info.is_follow != 1"
                @click="follow"
              >
                <i class="el-icon-plus"></i>
                <div class="ml-5">关注</div>
              </div>
            </div>
            <div class="font-14 text-white">
              {{ info.title }}
            </div>
          </div>
          <div class="py-20">
            <div class="font-14 text-white text-center">{{ commentNum }} 评论</div>
            <!-- 评论内容 -->
            <div class="comment_box px-40" @scroll="handleScroll($event)">
              <div v-for="(item, index) in comment" :key="index" class="flex">
                <div style="width: 3.3125rem; height: 3.3125rem">
                  <!-- <img
                    :src="item.avatar"
                    width="3.3125rem"
                    height="3.3125rem"
                    class="circle"
                    alt=""
                  /> -->
                  <el-image
                    class="circle"
                    style="width: 3.3125rem; height: 3.3125rem"
                    :src="info.avatar"
                    fit="cover"
                  ></el-image>
                </div>
                <div class="ml-25 x-1">
                  <div class="py-25 border-bottom border-262629 flex justify-between">
                    <div class="mr-10">
                      <div class="mb-15 font-14 text-999999">{{ item.username }}</div>
                      <div class="mb-20 font-14 text-white">{{ item.content }}</div>
                      <div class="font-12 text-999999">
                        {{ item.create_time }}
                        <span
                          class="ml-5 text-white cursorp"
                          @click="commentreply(2, item)"
                          >回复</span
                        >
                        <span
                          class="ml-5 text-white cursorp"
                          v-if="uid == item.user_id"
                          @click="commentreplydele(2, item.id)"
                          >删除</span
                        >
                      </div>
                    </div>
                    <div
                      class="flex align-center justify-center flex-column font-14"
                      :class="item.is_fabulous == 1 ? 'text-FF524C' : 'text-999999'"
                      @click="commentlike(item)"
                    >
                      <!-- <img
                        src="@/assets/img/zan_act.png"
                        alt=""
                        width="1.125rem"
                        height="0.9375rem"
                        class="cursorp"
                        v-if="item.is_fabulous == 1"
                      /> -->
                      <el-image
                        class="cursorp"
                        style="width: 1.125rem; height: 0.9375rem"
                        :src="require('@/assets/img/zan_act.png')"
                        fit="cover"
                        v-if="item.is_fabulous == 1"
                      ></el-image>
                      <!-- <img
                        src="@/assets/img/dianzan.png"
                        alt=""
                        width="1.125rem"
                        height="0.9375rem"
                        v-else
                        class="cursorp"
                      /> -->
                      <el-image
                        class="cursorp"
                        style="width: 1.125rem; height: 0.9375rem"
                        :src="require('@/assets/img/dianzan.png')"
                        fit="cover"
                        v-else
                      ></el-image>
                      <div class="mt-5">
                        {{ item.fabulous_nums }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="pb-20"
                    v-if="!item.flag && item.comment_child && item.comment_child.length"
                  >
                    <div class="flex py-10 border-bottom border-262629">
                      <div style="width: 3.3125rem; height: 3.3125rem">
                        <!-- <img
                          :src="item.comment_child[0].avatar"
                          width="3.3125rem"
                          height="3.3125rem"
                          class="circle"
                          alt=""
                        /> -->
                        <el-image
                          :src="item.comment_child[0].avatar"
                          style="width: 3.3125rem; height: 3.3125rem"
                          fit="cover"
                        ></el-image>
                      </div>
                      <div class="ml-20 x-1 flex justify-between">
                        <div class="mr-10">
                          <div class="mb-15 font-14 text-999999 flex align-center">
                            {{ item.comment_child[0].username }}
                            <div v-if="item.comment_child[0].reply_name">
                              <span class="text-white">
                                <i class="el-icon-caret-right"></i>
                              </span>
                              <span>{{ item.comment_child[0].reply_name }}</span>
                            </div>
                          </div>
                          <div class="mb-10 font-14 text-white">
                            {{ item.comment_child[0].content }}
                          </div>
                          <div class="font-12 text-999999">
                            {{ item.comment_child[0].create_time }}
                            <span
                              class="ml-5 text-white cursorp"
                              @click="commentreply(3, item.comment_child[0])"
                              >回复</span
                            >
                            <span
                              class="ml-5 text-white cursorp"
                              v-if="uid == item.comment_child[0].user_id"
                              @click="commentreplydele(3, item.comment_child[0].id)"
                              >删除</span
                            >
                          </div>
                        </div>
                        <div
                          class="flex align-center justify-center flex-column font-14"
                          :class="
                            item.comment_child[0].is_fabulous == 1
                              ? 'text-FF524C'
                              : 'text-999999'
                          "
                          @click="commentlike(item.comment_child[0])"
                        >
                          <el-image
                            :src="require('@/assets/img/zan_act.png')"
                            style="width: 1.125rem; height: 0.9375rem"
                            class="cursorp"
                            fit="cover"
                            v-if="item.comment_child[0].is_fabulous == 1"
                          ></el-image>
                          <!-- <img
                            src="@/assets/img/zan_act.png"
                            alt=""
                            width="1.125rem"
                            height="0.9375rem"
                            v-if="item.comment_child[0].is_fabulous == 1"
                            class="cursorp"
                          /> -->
                          <!-- <img
                            src="@/assets/img/dianzan.png"
                            alt=""
                            width="1.125rem"
                            height="0.9375rem"
                            class="cursorp"
                            v-else
                          /> -->
                          <el-image
                            :src="require('@/assets/img/dianzan.png')"
                            style="width: 1.125rem; height: 0.9375rem"
                            class="cursorp"
                            fit="cover"
                            v-else
                          ></el-image>
                          <div class="mt-5">
                            {{ item.comment_child[0].fabulous_nums }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="font-14 text-999999 text-center py-10 cursorp"
                      v-if="item.comment_child.length > 1"
                      @click="Itemcheck(true, index)"
                    >
                      展开{{ item.comment_child.length - 1 }}条回复
                    </div>
                  </div>
                  <div
                    class="pb-20"
                    v-if="item.flag && item.comment_child && item.comment_child.length"
                  >
                    <div
                      v-for="(items, indexs) in item.comment_child"
                      :key="indexs"
                      class="flex py-10 border-bottom border-262629"
                    >
                      <div style="width: 3.3125rem; height: 3.3125rem">
                        <!-- <img
                          :src="items.avatar"
                          width="3.3125rem"
                          height="3.3125rem"
                          class="circle"
                          alt=""
                        /> -->
                        <el-image
                          :src="items.avatar"
                          style="width: 3.3125rem; height: 3.3125rem"
                          class="cursorp"
                          fit="cover"
                        ></el-image>
                      </div>
                      <div class="ml-20 x-1 flex justify-between">
                        <div class="mr-10">
                          <div class="mb-15 font-14 text-999999 flex align-center">
                            {{ items.username }}
                            <div v-if="items.reply_name">
                              <span class="text-white">
                                <i class="el-icon-caret-right"></i>
                              </span>
                              <span>{{ items.reply_name }}</span>
                            </div>
                          </div>
                          <div class="mb-10 font-14 text-white">{{ items.content }}</div>
                          <div class="font-12 text-999999">
                            {{ items.create_time }}
                            <span
                              class="ml-5 text-white cursorp"
                              @click="commentreply(3, items)"
                              >回复</span
                            >
                            <span
                              class="ml-5 text-white cursorp"
                              v-if="uid == items.user_id"
                              @click="commentreplydele(3, items.id)"
                              >删除</span
                            >
                          </div>
                        </div>
                        <div
                          class="flex align-center justify-center flex-column font-14"
                          :class="items.is_fabulous == 1 ? 'text-FF524C' : 'text-999999'"
                          @click="commentlike(items)"
                        >
                          <el-image
                            :src="require('@/assets/img/zan_act.png')"
                            style="width: 1.125rem; height: 0.9375rem"
                            class="cursorp"
                            fit="cover"
                            v-if="items.is_fabulous == 1"
                          ></el-image>
                          <!-- <img
                            src="@/assets/img/zan_act.png"
                            alt=""
                            width="1.125rem"
                            height="0.9375rem"
                            v-if="items.is_fabulous == 1"
                            class="cursorp"
                          /> -->
                          <el-image
                            :src="require('@/assets/img/dianzan.png')"
                            style="width: 1.125rem; height: 0.9375rem"
                            class="cursorp"
                            fit="cover"
                            v-else
                          ></el-image>
                          <!-- <img
                            src="@/assets/img/dianzan.png"
                            alt=""
                            width="1.125rem"
                            height="0.9375rem"
                            class="cursorp"
                            v-else
                          /> -->
                          <div class="mt-5">
                            {{ items.fabulous_nums }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="font-14 text-999999 text-center py-10 cursorp"
                      @click="Itemcheck(false, index)"
                    >
                      收起{{ item.comment_child.length - 1 }}条回复
                    </div>
                  </div>
                </div>
              </div>
              <div class="font-14 mt-20 mb-20 text-white text-center">
                {{ bottomtext }}
              </div>
            </div>
            <!-- 输入框 -->
            <div class="comment_Input flex align-center">
              <el-input ref="input" v-model="contentText" placeholder="我伙说一说~">
                <template slot="prepend" v-if="placeholder">{{ placeholder }}</template>
              </el-input>
              <div class="comment_Input_btn" @click="Postcomment">发送</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import req from "../../utils/req";
import videoplay from "/src/components/videoplay2";
export default {
  components: {
    videoplay,
  },
  watch: {
    // 被侦听的变量count
    $route: {
      handler() {
        let moveid = this.$route.query.moveid;
        if (moveid) {
          this.moveid = moveid;
          this.getdata();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      bottomtext: "下拉加载更多~",
      placeholder: "",
      contentText: "",
      contentType: 1,
      contentId: 0,
      uid: localStorage.getItem("uid"),
      autoplay: true,
      value1: 0,
      videoData: {
        currentTime: 0, //学时
        duration: 0, //总时长
      },
      moveid: "",
      page: 1,
      page2: 1,
      info: "",
      comment: [],
      commentNum: 0,
    };
  },
  mounted() {
    let moveid = this.$route.query.moveid;
    if (moveid) {
      this.moveid = moveid;
      this.page = 1;
      this.page2 = 1;
      this.getdata(1);
      this.getdata2();
    }
  },

  methods: {
    Itemcheck(check, i) {
      this.comment[i].flag = check;
    },
    // 跳转用户详情
    gouserInfo() {
      this.$router.push({
        path: "/Mycreation",
        query: { type: "Mycreation", userId: this.info.user_id },
      });
    },
    // 关注
    follow() {
      let that = this;
      req
        .post("original/follow", {
          user_id: that.info.user_id,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          that.$message.success(res.msg);
          this.page = 1;
          this.page2 = 1;
          this.getdata();
          this.getdata2();
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
    // 评论点赞
    commentlike(item) {
      let that = this;
      req
        .post("original/upDown", {
          type: 1,
          comment_id: item.id,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          that.$message.success(res.msg);
          that.page = 1;
          that.getdata();
          that.page2 = 1;
          that.getdata2();
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
    // 发送评论
    Postcomment() {
      let that = this;
      if (this.contentType == 1) {
        req
          .post("original/comment", {
            original_id: that.info.id,
            content: that.contentText,
          })
          .then((res) => {
            if (res.code != 200) {
              that.$message.error(res.msg);
              return;
            }
            that.$message.success(res.msg);
            that.contentText = "";
            that.contentId = "";
            that.placeholder = "";
            that.page = 1;
            that.getdata();
            that.page2 = 1;
            that.getdata2();
          })
          .catch((err) => {
            console.log(err);
            that.$message.error(err.msg);
          });
      } else {
        req
          .post("original/reply", {
            comment_id: that.contentId,
            content: that.contentText,
          })
          .then((res) => {
            if (res.code != 200) {
              that.$message.error(res.msg);
              return;
            }
            that.$message.success(res.msg);
            that.contentText = "";
            that.contentId = "";
            that.placeholder = "";
            that.page = 1;
            that.getdata();
            that.page2 = 1;
            that.getdata2();
          })
          .catch((err) => {
            console.log(err);
            that.$message.error(err.msg);
          });
      }
    },
    // 回复评论
    commentreply(type, item) {
      this.contentText = "";
      if (type == 1) {
        this.contentType = 1;
        this.contentId = "";
        this.placeholder = "";
      } else {
        this.contentType = type;
        this.contentId = item.id;
        this.placeholder = "回复@" + item.username;
      }
      this.$refs.input.focus();
    },
    // 删除评论
    commentreplydele(item) {
      let that = this;
      that
        .$confirm("是否删除此评论?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          req
            .post("original/delComment", {
              comment_id: item.id,
            })
            .then((res) => {
              if (res.code != 200) {
                that.$message.error(res.msg);
                return;
              }
              that.$message.success(res.msg);
              that.page = 1;
              that.getdata();
              that.page2 = 1;
              that.getdata2();
            })
            .catch((err) => {
              console.log(err);
              that.$message.error(err.msg);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 点赞
    giveLike() {
      let that = this;
      req
        .post("original/fabulous", {
          original_id: that.info.id,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          that.$message.success(res.msg);
          this.page = 1;
          this.page2 = 1;
          this.getdata();
          this.getdata2();
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
    // 收藏
    givecollect() {
      let that = this;
      req
        .post("original/collection", {
          original_id: that.info.id,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          that.$message.success(res.msg);
          this.page = 1;
          this.page2 = 1;
          this.getdata();
          this.getdata2();
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
    // 获取页面滚动距离
    handleScroll(e) {
      let el = e.target;
      //判断是否到达div容器底部
      if (el.scrollTop + el.clientHeight >= el.scrollHeight) {
        //控制页数
        this.page2 = this.page2 + 1;
        //调用后台接口
        this.getdata2();
      }
    },
    // 增加点播量
    addplaynum() {
      let that = this;
      req
        .post("original/addPlay", {
          original_id: that.info.id,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          this.page = 1;
          this.page2 = 1;
          this.getdata();
          this.getdata2();
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
    getdata(type) {
      let that = this;
      req
        .post("original/videoInfo", {
          id: that.moveid,
          page: that.page,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          that.info = res.data.data[0];
          if (type == 1) {
            that.addplaynum();
          }
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
    getdata2() {
      let that = this;
      req
        .post("original/videoComment", {
          id: that.moveid,
          page: that.page2,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          if (res.data.comment.data && res.data.comment.data.length) {
            res.data.comment.data.forEach((item) => {
              item.flag = false;
            });
          }
          that.bottomtext = "下拉加载更多~";
          if (that.page2 == 1) {
            if (res.data.comment.data && res.data.comment.data.length) {
              that.comment = res.data.comment.data;
            } else {
              that.bottomtext = "";
            }
          } else {
            if (res.data.comment.data && res.data.comment.data.length) {
              that.comment = [...that.comment, ...res.data.comment.data];
            } else {
              that.bottomtext = "没有更多数据！";
            }
          }
          that.commentNum = res.data.all_nums;
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
  },
};
</script>

<style lang="scss">
.filmdetails {
  .w508 {
    width: 31.75rem;
  }
  .w1040 {
    width: 65rem;
  }
  box-sizing: border-box;
  .film_top {
    background: #131316;
    box-shadow: 0rem 0.0625rem 0rem 0rem #f0f0f0;
  }
  .videobox {
    width: 65.625rem;
    height: 39.25rem;
    position: relative;
    margin-right: 1.125rem;
    .videoplaybtn {
      position: absolute;
      top: 25%;
      right: 3%;
    }
  }
  .comment {
    width: 27.3125rem;
    height: 39.25rem;
    // padding: 0 1.6875rem;
    background-color: rgba(51, 51, 51, 0.3);
    .attention {
      width: 6.5625rem;
      height: 2.1875rem;
      background: #ff5950;
      border-radius: 0.25rem;
    }
    &_box {
      overflow: auto;
      height: 24.375rem;
    }
    &_box::-webkit-scrollbar {
      display: none;
    }
    &_Input {
      height: 2.8125rem;
      &_btn {
        width: 10rem;
        height: 2.8125rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-size: 0.875rem;
        background: #ff5950;
        cursor: pointer;
      }
    }
  }
}
</style>
<style lang="scss">
.comment {
  .el-input__inner {
    -webkit-appearance: none;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 0;
    border: 0;
    box-sizing: border-box;
    color: #ffffff;
    display: inline-block;
    font-size: inherit;
    height: 2.8125rem;
    line-height: 2.8125rem;
    outline: 0;
    padding: 0 0.9375rem;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
  }
  .el-input-group__append,
  .el-input-group__prepend {
    background-color: rgba(255, 255, 255, 0.3);
    color: #ffffff;
    vertical-align: middle;
    display: table-cell;
    position: relative;
    border: 0;
    border-radius: 0;
    padding: 0 0.625rem;
    width: 0;
    white-space: nowrap;
  }
}
</style>
